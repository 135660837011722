<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalQuickView"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="dataQuickView.infoListing.sw012s02 !== ''"
            class="mb-0"
          >
            {{ dataQuickView.infoListing.sw012s02 }}
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            {{ $t('Pré-angariação') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <vue-perfect-scrollbar
          class="b-sidebar-scrollarea-form"
          :settings="configSettingsScroll"
        >

          <b-container
            class="pt-2 pb-0 pl-2 pr-2"
            fluid
          >
            <b-row
              v-show="showMoreOptions === true && typeof dataQuickView.headerListing.optionsAction !== 'undefined' && dataQuickView.headerListing.optionsAction !== null"
            >
              <b-col md="12">
                <app-collapse
                  type="margin"
                  class="mb-1"
                >
                  <app-collapse-item
                    ref="blockMoreActions"
                    :title="$t('Ações')"
                  >
                    <action-listing
                      :show-quick-view-general="true"
                      :reload-quick-view="reloadView"
                      :set-show-user-share-details="setShowUserShareDetails"
                    />
                  </app-collapse-item>
                </app-collapse>

              </b-col>
            </b-row>

            <info-Listing
              :get-modal-rating-listing="getModalRatingListing"
              :mode-form="true"
            />
          </b-container>

        </vue-perfect-scrollbar>

        <div
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>
        <b-overlay
          :show="$store.getters['listings/loadingListview']"
          no-wrap
        />

      </template>
    </b-sidebar>

    <!-- Share View/Detail -->
    <share-view
      v-if="showUserShareDetails === true"
      :quick-view-general="true"
      @close-share-view="setShowUserShareDetails([false, null, null])"
    />

    <component
      :is="ModalRatingListing"
      v-if="showModalRating === true"
      ref="modalRatingListing"
      :rating="rating"
      :form-mode-view="true"
      :iso="$store.getters['auth/countryApp'].toUpperCase()"
    />
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import {
  VBTooltip, BSidebar, BContainer, BButton, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import listingsModule from '@store-modules/listings/listview/index'
import shareModule from '@store-modules/listings/share/index'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import InfoListing from '@/views/modules/components/listing/infoQuickView.vue'
import ActionListing from '@/views/modules/listings/listview/options.vue'
import ShareView from '@/views/modules/listings/share/listviewUsersShareListing.vue'

export default {
  components: {
    BSidebar,
    BContainer,
    BButton,
    BOverlay,
    BRow,
    BCol,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,
    InfoListing,
    ActionListing,
    ShareView,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  props: {
    idListing: {
      type: String,
      required: true,
      default: '',
    },
    showMoreOptions: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeModal: {
      type: Function,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      showModalRating: false,
      rating: {},
    }
  },
  computed: {
    ...mapGetters('listings', ['dataQuickView']),
    ...mapGetters('share', ['showUserShareDetails']),
    ...mapGetters('auth', ['currentUser']),
  },
  async mounted() {
    await this.reloadView()
  },
  methods: {
    closeModalQuickView() {
      if (typeof this.$parent.closeModalQuickView !== 'undefined') {
        this.$parent.closeModalQuickView()
      } else if (typeof this.closeModal !== 'undefined' && this.closeModal !== null) {
        this.closeModal()
      }
    },
    async reloadView() {
      // await this.closeModalQuickView()

      // if (typeof this.$parent.openViewListing !== 'undefined') {
      //   await this.$parent.openViewListing({ id: atob(this.idListing) })
      // }
      await this.$store.dispatch('listings/previewQuickListing', {
        keyReg: this.idListing || '',
      }).catch(error => {
        this.closeModalQuickView()
        this.showMsgErrorRequest(error)
      })
    },
    async getModalRatingListing(idListing, versionPending) {
      this.$root.$emit('app::loading', true)

      const formData = new FormData()

      formData.append('sw012s01', idListing)
      formData.append('versionPending', versionPending)

      await this.$store.dispatch('listings/previewModalRating', formData).then(async res => {
        this.rating = res.data.rating || {}
        this.$root.$emit('app::loading', false)

        this.showModalRating = true

        setTimeout(() => {
          this.$refs.modalRatingListing.showModal().then(async result => {
            await this.$refs.modalRatingListing.hideModal()
            this.showModalRating = false
            if (result === true) {
              setTimeout(() => {
                this.rating = {}
              }, 500)
            }
          })
        }, 500)
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        throw error
      })
    },
    async setShowUserShareDetails(newValue) {
      await this.$store.dispatch('share/setShowUserShareDetails', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const LISTINGS_MODULE_NAME = 'listings'
    const SHARE_MODULE_NAME = 'share'

    if (!store.hasModule(LISTINGS_MODULE_NAME)) {
      store.registerModule(LISTINGS_MODULE_NAME, listingsModule)

      onUnmounted(() => {
        if (store.hasModule(LISTINGS_MODULE_NAME)) store.unregisterModule(LISTINGS_MODULE_NAME)
      })
    }

    if (!store.hasModule(SHARE_MODULE_NAME)) {
      store.registerModule(SHARE_MODULE_NAME, shareModule)

      onUnmounted(() => {
        if (store.hasModule(SHARE_MODULE_NAME)) store.unregisterModule(SHARE_MODULE_NAME)
      })
    }

    const ModalRatingListing = () => import(/* webpackChunkName: "listing-quickview-modal-rating" */ /* webpackPrefetch: true */ '@/views/modules/listings/form/form_data/modal/rating.vue')

    return {
      configSettingsScroll,
      ModalRatingListing,
    }
  },
}
</script>

<style lang="scss" scoped>

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
